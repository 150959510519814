import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetch } from '../lib/fetch';

const updateRestaurantsRequest = async data =>
  fetch(`/api/restaurants`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

const useUpdateRestaurants = () => {
  const queryClient = useQueryClient();

  return useMutation(newRestaurantsData => updateRestaurantsRequest(newRestaurantsData), {
    onMutate: async newRestaurantsData => {
      await queryClient.cancelQueries({ queryKey: ['restaurants'], exact: true });

      const previousRestaurants = queryClient.getQueryData(['restaurants']);
      queryClient.setQueryData(['restaurants'], old => {
        // Map over the old data; if there is new data for a restaurant then
        // override the existing properties
        const merge = old.map(restaurant => {
          return {
            ...restaurant,
            ...newRestaurantsData.find(newRestaurant => newRestaurant.restaurantId === restaurant.id),
          };
        });
        return merge;
      });

      return { previousRestaurants };
    },
    onError: context => {
      queryClient.setQueryData('restaurants', context.previousRestaurants);
    },
    onSettled: () => {
      queryClient.invalidateQueries('restaurants');
    },
  });
};

export { useUpdateRestaurants };
