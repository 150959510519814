import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { fetch } from '../lib/fetch';

const updateRestaurantRequest = async data =>
  fetch(`/api/restaurants/${data.id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orderingEnabled: data.orderingEnabled,
      tableOrderingEnabled: data.tableOrderingEnabled,
      deliveryEnabled: data.deliveryEnabled,
      pickUpEnabled: data.pickUpEnabled,
      dineInGuestCheckoutEnabled: data.dineInGuestCheckoutEnabled,
      deliveryGuestCheckoutEnabled: data.deliveryGuestCheckoutEnabled,
      pickUpGuestCheckoutEnabled: data.pickUpGuestCheckoutEnabled,
    }),
  });

const useUpdateRestaurant = () => {
  const router = useRouter();

  return useMutation(newRestaurantData => updateRestaurantRequest(newRestaurantData), {
    onSuccess: () => router.push('/'),
  });
};

export { useUpdateRestaurant };
