import { Box, Heading } from '@inlight-media/react-components';
import { useSession } from 'next-auth/react';

import { Error } from '../components/error';
import { Layout } from '../components/layout';
import { NotSignedIn } from '../components/not-signed-in';
import { Table } from '../components/table';
import { useFetchRestaurants } from '../hooks';

export default function Home() {
  const { data: session } = useSession();
  const { data, status, error } = useFetchRestaurants();

  return (
    <Layout>
      <Box textAlign="center" p={2}>
        {!session ? (
          <NotSignedIn />
        ) : (
          <>
            <Heading variant="h1" mb={3}>
              Nando’s Portal
            </Heading>
            {status === 'loading' && <div>Loading restaurant data...</div>}
            {status === 'error' && <Error error={error} />}
            {data && <Table queryData={data} />}
          </>
        )}
      </Box>
    </Layout>
  );
}
