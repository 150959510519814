import { createGlobalStyle } from 'styled-components';

const BaseStyles = createGlobalStyle`
  @font-face {
    font-family: 'NandosHandAlt';
    font-style: normal;
    font-weight: 400;
    src: local('NandosHandAlt'),
      url('/static/fonts/nandos-hand-alt.woff2') format('woff2'),
      url('/static/fonts/nandos-hand-alt.woff') format('woff'),
      url('/static/fonts/nandos-hand-alt.ttf') format('truetype'),
      url('/static/fonts/nandos-hand-alt.eot') format('embedded-opentype');
      url('/static/fonts/nandos-hand-alt.otf') format('opentype');
  }
`;

export { BaseStyles };
