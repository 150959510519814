import { Button, Flex } from '@inlight-media/react-components';
import { signIn, signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

const Header = () => {
  const { data: session } = useSession();

  return (
    <StyledFlex width="100%" justifyContent="space-between" alignItems="center" p={1}>
      <div>
        <Link href="/" passHref>
          <StyledA>Restaurants</StyledA>
        </Link>

        <Link href="/audit-log" passHref>
          <StyledA>Audit Log</StyledA>
        </Link>

        <Link href="/refund-log" passHref>
          <StyledA>Refund Log</StyledA>
        </Link>

        <Link href="/launch-darkly-flag-tool" passHref>
          <StyledA>LD Flag Tool</StyledA>
        </Link>
      </div>

      <div>
        {!session && (
          <>
            <span>Not signed in</span>
            <Button ml={2} onClick={() => signIn('cognito', { callbackUrl: process.env.CALLBACK_URL })}>
              Sign in
            </Button>
          </>
        )}
        {session && (
          <>
            <span>Signed in as {session.user.email}</span>
            <Button ml={2} onClick={() => signOut()}>
              Sign out
            </Button>
          </>
        )}
      </div>
    </StyledFlex>
  );
};

const StyledA = styled.a`
  margin-right: 1rem;
`;

const StyledFlex = styled(Flex)`
  background: ${colors.grey100};
`;

export { Header };
