import Head from 'next/head';
import PropTypes from 'prop-types';

import { BaseStyles } from '../base-styles';
import { Header } from '../header';

const Layout = ({ children }) => (
  <>
    <Head>
      <title>Nando’s Portal</title>
    </Head>
    <div>
      <BaseStyles />
      <Header />
      <main>{children}</main>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
