import { Heading, Text } from '@inlight-media/react-components';
import PropTypes from 'prop-types';

const Error = ({ error, heading }) => (
  <>
    {heading && <Heading variant="h2">{heading}</Heading>}
    <Text>Sorry, we encountered an error. Please try refreshing the page.</Text>
    <Text>{error.message}</Text>
  </>
);

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  heading: PropTypes.string,
};

Error.defaultProps = {
  heading: 'Error',
};

export { Error };
