import { useQuery } from '@tanstack/react-query';

import { fetch } from '../lib/fetch';

const useFetchRefundLogData = ({ enabled, restaurantId }) => {
  return useQuery({
    queryKey: ['refund-log-data', restaurantId],
    queryFn: () => fetch(`/api/refund-log-data?${restaurantId ? new URLSearchParams({ restaurantId }) : ''}`),
    enabled,
    cacheTime: 0,
  });
};

export { useFetchRefundLogData };
