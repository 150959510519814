import * as Switch from '@radix-ui/react-switch';
import * as Tooltip from '@radix-ui/react-tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

const getSliderColor = (value, disabled) => {
  if (disabled) {
    return colors.grey100;
  }
  return value === true ? colors.green500 : colors.grey200;
};

const Toggle = ({ onClick, value, disabled }) => {
  return (
    <Tooltip.Provider delayDuration={200} skipDelayDuration={200}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <SwitchRoot checked={value} disabled={disabled} onCheckedChange={onClick}>
            <Thumb value={value} disabled={disabled} />
          </SwitchRoot>
        </Tooltip.Trigger>
        {disabled && (
          <Tooltip.Portal>
            <TooltipContent sideOffset={5}>
              Data is populated by Redcat
              <TooltipArrow />
            </TooltipContent>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const SwitchRoot = styled(Switch.Root)`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Thumb = styled(Switch.Thumb)`
  background-color: ${({ value, disabled }) => getSliderColor(value, disabled)};
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${({ disabled }) => (disabled ? colors.grey50 : 'white')};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    ${({ value }) =>
      value &&
      `
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    `}
  }
`;

const TooltipContent = styled(Tooltip.Content)`
  border-radius: 5px;
  padding: 5px;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
`;

const TooltipArrow = styled(Tooltip.Arrow)`
  fill: rgba(0, 0, 0, 0.7);
`;

Toggle.defaultProps = {
  disabled: false,
};

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export { Toggle };
