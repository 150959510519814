const regionsByMarket = {
  au: [
    { value: 'ACT', longName: 'Australian Capital Territory' },
    { value: 'NSW', longName: 'New South Wales' },
    { value: 'NT', longName: 'Northern Territory' },
    { value: 'QLD', longName: 'Queensland' },
    { value: 'SA', longName: 'South Australia' },
    { value: 'TAS', longName: 'Tasmania' },
    { value: 'VIC', longName: 'Victoria' },
    { value: 'WA', longName: 'Western Australia' },
  ],
  nz: [
    { longName: 'Auckland', value: 'AUCK' },
    { longName: 'Bay of Plenty', value: 'BOP' },
    { longName: 'Christchurch', value: 'CHCH' },
    { longName: 'Dunedin', value: 'DUN' },
    { longName: 'Hamilton', value: 'HAM' },
    { longName: 'Wellington', value: 'WEL' },
  ],
};

export { regionsByMarket };
