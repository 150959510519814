const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey50: '#F6F6F6',
  grey100: '#E9E9E9',
  grey200: '#D3D3D3',
  grey300: '#B9B9B9',
  grey500: '#7F7F7F',
  grey700: '#4C4C4C',
  grey800: '#2D2D2D',
  background: '#FAF7F0',
  backgroundDark: '#EDE5CE',
  backgroundLight: '#F4EEDF',
  green500: '#48BB78',
};

export { colors };
